#upbutton {
    color: Mediumslateblue;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
}

#upbutton:hover {
    cursor: pointer;
}

#upbutton.show {
    opacity: 1;
    visibility: visible;
}


/* Styles for the content section */

@media (min-width: 500px) {
    #upbutton {
        margin: 30px;
    }
}