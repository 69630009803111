// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font-Awesome
@import '~@fortawesome/fontawesome-free/css/all.css';

// lightbox2
@import '~lightbox2/dist/css/lightbox.css';

// Scroll up button
@import 'scrollup';

//styles for font awesome icons as links
@import 'linkbutton';